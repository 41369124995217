import DataService from '../../services/DataService';
import HelperService from '../../services/HelperService';
import ContactForm from '../forms/ContactForm';
import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BiBrain } from 'react-icons/bi';
import { BsChat, BsGear, BsListCheck, BsListUl, BsShieldCheck } from 'react-icons/bs';
import { GiFist, GiPendulumSwing } from 'react-icons/gi';
import { GoSettings } from 'react-icons/go';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { TbCross, TbGauge, TbTie } from 'react-icons/tb';
import { FaLightbulb } from 'react-icons/fa';
import { ParallaxBanner } from 'react-scroll-parallax';

export interface IHomeComponentProps {
	dataService: DataService;
}

interface IHomeComponentState {

}

export default class Home extends React.Component<IHomeComponentProps, IHomeComponentState> {

	constructor(props: IHomeComponentProps) {
		super(props);

		this.state = {
		}
	}

    public render() {
        return(
            <div className='fullwidth'>
                <ParallaxBanner
					className='header'
					layers={[
						{
							translateY: [0, 30],
							scale: [1.03, 1, 'easeOutCubic'],
							shouldAlwaysCompleteAnimation: true,
							children: (
								<picture>
									<source
										media="(orientation: landscape)"
										type="image/webp"
										sizes={`
											(min-width: 3601px)) 4000px,
											((min-width: 3001px) and (max-width: 3600px)) 3600px,
											((min-width: 2401px) and (max-width: 3000px)) 3000px,
											((min-width: 1801px) and (max-width: 2400px)) 2400px,
											((min-width: 1401px) and (max-width: 1800px)) 1800px,
											((min-width: 1001px) and (max-width: 1400px)) 1400px,
											((min-width: 801px) and (max-width: 1000px)) 1000px,
											((min-width: 601px) and (max-width: 800px)) 800px,
											((min-width: 401px) and (max-width: 600px)) 600px,
											((min-width: 301px) and (max-width: 400px)) 400px,
											300px
										`}
										srcSet={`
											/img/startup_background_300.webp 300w,
											/img/startup_background_400.webp 400w,
											/img/startup_background_600.webp 600w,
											/img/startup_background_800.webp 800w,
											/img/startup_background_1000.webp 1000w,
											/img/startup_background_1200.webp 1400w,
											/img/startup_background_1800.webp 1800w,
											/img/startup_background_2400.webp 2400w,
											/img/startup_background_3000.webp 3000w,
											/img/startup_background_3600.webp 3600w,
											/img/startup_background_4000.webp 4000w,
										`}
									/>
									<source
										media="(orientation: portrait)"
										type="image/webp"
										sizes={`
											(min-height: 2325px)) 4000px,
											((min-height: 1938px) and (max-height: 2324px)) 3600px,
											((min-height: 1550px) and (max-height: 1937px)) 3000px,
											((min-height: 1163px) and (max-height: 1549px)) 2400px,
											((min-height: 776px) and (max-height: 1162px)) 1800px,
											((min-height: 647px) and (max-height: 775px)) 1400px,
											((min-height: 517px) and (max-height: 646px)) 1000px,
											((min-height: 388px) and (max-height: 516px)) 800px,
											((min-height: 259px) and (max-height: 387px)) 600px,
											((min-height: 195px) and (max-height: 258px)) 400px,
											300px
										`}
										srcSet={`
											/img/startup_background_300.webp 300w,
											/img/startup_background_400.webp 400w,
											/img/startup_background_600.webp 600w,
											/img/startup_background_800.webp 800w,
											/img/startup_background_1000.webp 1000w,
											/img/startup_background_1200.webp 1400w,
											/img/startup_background_1800.webp 1800w,
											/img/startup_background_2400.webp 2400w,
											/img/startup_background_3000.webp 3000w,
											/img/startup_background_3600.webp 3600w,
											/img/startup_background_4000.webp 4000w,
										`}
									/>
									<img
										className="header-background"
										alt="Profilbild"
										src="/img/startup_background_300.webp"
									/>
								</picture>
							)
						},
						{
							translateY: [0, 30],
							scale: [1.03, 1, 'easeOutCubic'],
							shouldAlwaysCompleteAnimation: true,
							children: (
								<div className='header-gradient-background' />
							)
						},
						{
							translateY: [0, 45],
							scale: [1, 1.1, 'easeOutCubic'],
							shouldAlwaysCompleteAnimation: true,
							expanded: false,
							children: (
								<div className='header-textbox'>
									<h1 className='header-textbox-headline font-xxl'>Der<br/>Bewusstmacher</h1>
								</div>
							)
						},
						{
                            opacity: [0, 0.5],
							shouldAlwaysCompleteAnimation: true,
							expanded: false,
							children: (
                                <div className="header-gradient-full" />
							)
						}
					]}
				/>

				<div className="content">
					
					{/* Person */}
					<div className="box">
							<div className="profile">
								<img
									alt="Profilbild"
									src="/img/profile_picture_400.webp"
									srcSet={`
										/img/profile_picture_400.webp 1x,
										/img/profile_picture_800.webp 2x,
										/img/profile_picture_1200.webp 3x,
										/img/profile_picture_1400.webp 4x,
									`}
									width="350"
								/>
							</div>
							<div>
								<h2>J&uuml;rgen Effenberger</h2>
								
								<p>Ich bin 60 Jahre alt und verfüge dementsprechend über einige Lebens-Erfahrung. Durch sie und meine Kreativität gelingt es mir, aus jedem Erfahrungsbereich die beste Möglichkeit für die jeweilige Coaching-Situation zu nutzen.</p>

								<p>Aktuell bin ich im Bereich Gesundheit/Medizin im Einsatz. Dies direkt am "Patienten" und seit 25 Jahren Dozent in der Ausbildung zum Physiotherapeuten. Seit ca 15 Jahren zusätzlich im Coaching. Anfangs eher in Gruppen, später - weil mich die Erkenntnis traf, dass systematische Persönlichkeitsentwicklung nur einzeln funktioniert - überwiegend im 1:1-Coaching.</p>

								<p>Ich bin schon sehr lange in den Themen/Bereichen systematische Persönlichkeitsentwicklung, Aus- und Weiterbildung im Sport und der Pädagogik tätig. Dabei lege ich mittlerweile besonderen Wert auf das Thema "Führung" und Reflexion. Mit allen Themen die für die Entwicklung in diesen Bereichen hilfreich sind. Als Beispiel sei hier die Kommunikation und der Gebrauch von Begriffen und Sprache erwähnt. Wie benutze ich bestimmte Begriffe und was kann ich daraus über mich ableiten. Ein Mittel, das regelmäßig zum Einsatz kommt ist das "Teamtraining". Hier geht es um themengesteuerten Einsatz gruppendynamischer Themen und Prozesse. Dabei kommen Übungen aus dem erlebnispädagogischen Bereich zum Einsatz.</p>

								<p>Mir sind Werte sehr wichtig! Werte, die im Zusammenhang mit der Arbeit mit Menschen entscheidend für Erfolg oder Misserfolg einer Maßnahme sein können. An oberster Stelle sei die Zuverlässigkeit genannt. Sie spielt eine große Rolle bei der Zusammenarbeit mit anderen Menschen, sondern auch sich selbst gegenüber.</p>

								<p>Ich sehe meine Coaching-Rolle ähnlich der eines Coaches im Sport. Ich spiele selbst nicht aktiv in der Mannschaft mit, ich bin systemisch nicht beteiligt. Das gibt mir einen "freien" Blick auf die Situationen und Verhalten der Teilnehmer.</p>

							</div>
					</div>

					{/* Textblocks */}
					<div className="centered box">

						<h2>Entwickeln Sie sich - mit meiner Hilfe - Verändern können Sie sich auch alleine</h2>

						<div className="font-l hl">Führung kann man lernen!</div>

						<p>
							Worum geht es bei meinem Coaching? Es gilt zu erkennen, vielleicht auch zu erfahren, was ich will (Nicht: "was ich nicht will"). Identifizieren/Festlegen von Zielen. Ich gebe Anregungen - Ich gebe Ihnen eine andere Perspektive - Ich lasse Sie andere Sichtweisen kennen lernen - Ich lehre Sie Führen - Ich beobachte mit Ihnen zusammen Ihr eigenes Verhalten, analysiere es mit Ihnen und gebe Ihnen Möglichkeiten es zu entwickeln - Ich helfe Ihnen Ihre eigenen Bedürfnisse zu erkennen und danach zu handeln - dabei beziehe ich den sozialen Kontext mit ein - Ich zeige Ihnen Muster und Möglichkeiten sie zu verändern.
						</p>

					</div>

					<div className="centered box">

						<div className="font-l pc hl">Bewusst - Systematisch - Konsequent</div>
						<div className="font-l mobile hl">
							Bewusst
							<br/>
							-
							<br/>
							Systematisch
							<br/>
							-
							<br/>
							Konsequent
						</div>
					</div>

					<div className="box centered">
						<p>
							Nachfolgend können Sie bestimmte Begriffe anklicken und meine Beschreibung lesen.
							Ich nenne sie bewusst nicht Definition!
							Sie sollen Sie anregen, sich darüber Gedanken zu machen - oder auf Ihr Gefühl zu vertrauen - wie Sie diese Begriffe verwenden/einsetzen. Ein Wichtiges ist dabei deren Abgrenzung ähnlicher Begriffe:
						</p>

						<br/>
						<br/>

						<IconBoxes
							elements={HelperService.shuffle([
								{text: "Verlässlichkeit", icon: <BsShieldCheck />},
								{text: "Organisation", icon: <BsListCheck />},
								{text: "Kommunikation", icon: <BsChat />},
								{text: "Führung", icon: <TbTie />},
								{text: "Außenwirkung", icon: <GiPendulumSwing />},
								{text: "Glaubenssätze", icon: <TbCross />},
								{text: "Resilienz", icon: <TbGauge />},
								{text: "Rollen", icon: <HiOutlineUserGroup />},
								{text: "Einordnung", icon: <BsListUl />},
								{text: "Konflikte", icon: <GiFist />},
								{text: "Charakter", icon: <AiOutlineUser />},
								{text: "Eigenschaften", icon: <GoSettings />},
								{text: "Gedanken", icon: <BiBrain />},
								{text: "Logik ≠ Gefühle", icon: <BsGear />},
								{text: "Erkenntnisse", icon: <FaLightbulb />},
							])}
						/>
						<br/>
						<br/>

					</div>

					<div id="contact" className="box centered">

							<h2>Kontakt</h2>

							<ContactForm dataService={this.props.dataService} />

					</div>

				</div>
				
            </div>
        );
    }
}

function IconBoxes(props: {elements: {text: string, icon: JSX.Element}[]}): JSX.Element {
	return(
		<div className="box-elements">
			{props.elements.map(prop => {
				let move: number = Math.random() * 70;
				return(
					<div key={prop.text} className="centered" style={{transform: `rotate(${(Math.random() * 70) - 35}deg)`, margin: `0 ${move + 10}px`, }} >
						<div className="font-l icon">
							{prop.icon}
						</div>
						<div>
							{prop.text}
						</div>
					</div>
				);
			})}
		</div>
	);
}