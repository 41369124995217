import React from "react";
import { Link } from "react-router-dom";

export default class Impressum extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="fullwidth">

                <h1 className="font-xl headline">Impressum</h1>

                <div className="content">
                    <div className="box">
                        <b className="hl">J&uuml;rgen Effenberger</b>
                        <br />
                        Dresdner Straße 5
                        <br />
                        63762 Großostheim
                        <br />
                        Deutschland
                    </div>

                    <div className="box">
                        <b className="hl">Kontakt</b>
                        <br />
                        E-Mail: <a href="mailto:juergen@derbewusstmacher.de">juergen@derbewusstmacher.de</a>
                        <br />
                        Web: <Link to="/">DerBewusstmacher.de</Link>
                    </div>

                    <div className="box">
                        <b className="hl">Verantwortlich f&uuml;r den Inhalt</b>
                        <br />
                        J&uuml;rgen Effenberger
                    </div>

                    <div className="box">
                        <b className="hl">Webdesign und Realisierung</b>
                        <br />
                        Julian Effenberger
                    </div>

                    <div className="box">
                        <b className="hl">Bildnachweis</b>
                        <br />
                        J&uuml;rgen Effenbeger
                    </div>
                </div>
            </div>
        );
    }
}