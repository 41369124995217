import React from "react"
import DataService from "../../services/DataService";
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import { emailValidator } from "../../models/Validators";
import { Messages } from 'primereact/messages';

export interface ILoginFormProps {
    dataService: DataService;
    onLogin: () => void;
}

interface ILoginFormState {
    showErrors: boolean;
    sending: boolean;

    email: string;
    password: string;

    emailError: string;
    passwordError: string;
}

export default class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {

    private messages: React.RefObject<Messages> = React.createRef<Messages>();

    constructor(props: ILoginFormProps) {
        super(props);

        this.state = {
            showErrors: false,
            sending: false,
            email: "",
            password: "",
            emailError: "",
            passwordError: "",
        }
    }

    public render() {
        return(
            <div className="form">

                <form onSubmit={(ev) => {ev.preventDefault();}}>

                    <Messages className="field" ref={this.messages} />

                    <div className="field">
                        <div className="p-float-label field-input">
                            <InputText id="email" className={"block" + ((this.state.showErrors && this.state.emailError) ? " p-invalid" : "")} value={this.state.email} onChange={(e) => this.onChangeEmail(e.target.value)} aria-describedby="email-error" />
                            <label htmlFor="email" className="block">E-Mail</label>
                        </div>
                        {this.state.showErrors && this.state.emailError &&
                            <small id="email-error" className="p-error block">{this.state.emailError}</small>
                        }
                    </div>

                    <div className="field">
                        <div className="p-float-label field-input">
                            <Password id="password" className={"block" + ((this.state.showErrors && this.state.passwordError) ? " p-invalid" : "")} value={this.state.password} onChange={(e) => this.onChangePassword(e.target.value)} aria-describedby="password-error" feedback={false} toggleMask />
                            <label htmlFor="password" className="block">Passwort</label>
                        </div>
                        {this.state.showErrors && this.state.passwordError &&
                            <small id="password-error" className="p-error block">{this.state.passwordError}</small>
                        }
                    </div>

                    <Button type="submit" label="Anmelden" icon="pi pi-send" loading={this.state.sending} onClick={() => this.submit()} />

                </form>

            </div>
        );
    }

    private onChangeEmail(value: string) {
        this.setState({
            email: value,
        }, () => {
            this.checkInputs();
        });
    }

    private onChangePassword(value: string) {
        this.setState({
            password: value,
        }, () => {
            this.checkInputs();
        });
    }

    private async checkInputs(): Promise<boolean> {
        if(!this.state.showErrors)
            return true;
        
        return new Promise<boolean>(resolve => {
            let emailError: string = "";
            let passwordError: string = "";

            if(!this.state.email || !this.state.email.toLowerCase().match(emailValidator)) {
                emailError = "Bitte giben Sie eine gültige E-Mail-Adresse an";
            }

            if(!this.state.password || this.state.password.length < 8 || this.state.password.length > 200) {
                passwordError = "Bitte geben Sie ein gültiges Passwort an";
            }

            this.setState({
                emailError: emailError,
                passwordError: passwordError,
            }, () => {
                resolve((emailError || passwordError) ? false : true);
                return;
            });
        });
    }

    private async submit() {
        this.setState({
            showErrors: true,
        }, async () => {
            if(await this.checkInputs()) {
                this.setState({
                    sending: true,
                }, async () => {
                    this.messages.current?.clear();
                    await this.props.dataService.login(
                        this.state.email,
                        this.state.password,
                    )
                    .then(() => {
                        this.setState({
                            sending: false,
                            email: "",
                            password: "",
                            showErrors: false,
                        }, () => {this.props.onLogin()});
                    })
                    .catch((e) => {
                        let errorCode: number = 0;
                        if(isNaN(+e)) {
                            errorCode = e.response.status;
                        } else {
                            errorCode = e;
                        }

                        let message: string;
                        switch(errorCode) {
                            case 401:
                                message = "E-Mail-Adresse und Passwort stimmen nicht überein"
                                break;
                            default:
                                message = "Es ist ein unbekannter Fehler aufgetreten";
                                console.error(e);
                        }

                        this.setState({
                            sending: false
                        }, () => this.messages.current?.show([{ severity: "error", summary: message, sticky: true }]));
                    });
                });
            }
        });
    }

}