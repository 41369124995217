import React from "react";
import DataService from "../../services/DataService";
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { emailValidator } from "../../models/Validators";
import { Messages } from 'primereact/messages';

export interface IContactFormProps {
    dataService: DataService;
}

interface IContactFormState {
    showErrors: boolean;
    sending: boolean;

    name: string;
    email: string;
    subject: string;
    message: string;

    nameError: string;
    emailError: string;
    subjectError: string;
    messageError: string;
}

export default class ContactForm extends React.Component<IContactFormProps, IContactFormState> {

    private messages: React.RefObject<Messages> = React.createRef<Messages>();

    constructor(props: IContactFormProps) {
        super(props);

        this.state = {
            showErrors: false,
            sending: false,
            name: "",
            email: "",
            subject: "",
            message: "",
            nameError: "",
            emailError: "",
            subjectError: "",
            messageError: "",
        }
    }

    public render() {
        return(
            <div className="form">

                <form onSubmit={(ev) => {ev.preventDefault();}}>

                    <Messages className="field" ref={this.messages} />

                    <div className="field">
                        <div className="p-float-label field-input">
                            <InputText id="username" className={"block" + ((this.state.showErrors && this.state.nameError) ? " p-invalid" : "")} value={this.state.name} onChange={(e) => this.onChangeName(e.target.value)} aria-describedby="username-error" />
                            <label htmlFor="username" className="block">Name</label>
                        </div>
                        {this.state.showErrors && this.state.nameError &&
                            <small id="username-error" className="p-error block">{this.state.nameError}</small>
                        }
                    </div>

                    <div className="field">
                        <div className="p-float-label field-input">
                            <InputText id="email" className={"block" + ((this.state.showErrors && this.state.emailError) ? " p-invalid" : "")} value={this.state.email} onChange={(e) => this.onChangeEmail(e.target.value)} aria-describedby="email-error" />
                            <label htmlFor="email" className="block">E-Mail</label>
                        </div>
                        {this.state.showErrors && this.state.emailError &&
                            <small id="email-error" className="p-error block">{this.state.emailError}</small>
                        }
                    </div>

                    <div className="field">
                        <div className="p-float-label field-input">
                            <InputText id="subject" className={"block" + ((this.state.showErrors && this.state.subjectError) ? " p-invalid" : "")} value={this.state.subject} onChange={(e) => this.onChangeSubject(e.target.value)} aria-describedby="subject-error" />
                            <label htmlFor="subject" className="block">Betreff</label>
                        </div>
                        {this.state.showErrors && this.state.subjectError &&
                            <small id="subject-error" className="p-error block">{this.state.subjectError}</small>
                        }
                    </div>

                    <div className="field">
                        <div className="p-float-label field-input">
                            <InputTextarea id="message" aria-describedby="message-error" className={"block" + ((this.state.showErrors && this.state.messageError) ? " p-invalid" : "")} value={this.state.message} onChange={(e) => this.onChangeMessage(e.target.value)} rows={10} autoResize />
                            <label htmlFor="message" className="block">Nachricht</label>
                        </div>
                        {this.state.showErrors && this.state.messageError &&
                            <small id="message-error" className="p-error block">{this.state.messageError}</small>
                        }
                    </div>

                    <Button type="submit" label="Senden" icon="pi pi-send" loading={this.state.sending} onClick={() => this.submit()} />

                </form>

            </div>
        );
    }

    private onChangeName(value: string) {
        this.setState({
            name: value,
        }, () => {
            this.checkInputs();
        });
    }

    private onChangeEmail(value: string) {
        this.setState({
            email: value,
        }, () => {
            this.checkInputs();
        });
    }

    private onChangeSubject(value: string) {
        this.setState({
            subject: value,
        }, () => {
            this.checkInputs();
        });
    }

    private onChangeMessage(value: string) {
        this.setState({
            message: value,
        }, () => {
            this.checkInputs();
        });
    }

    private async checkInputs(): Promise<boolean> {
        if(!this.state.showErrors)
            return true;
        
        return new Promise<boolean>(resolve => {
            let nameError: string = "";
            let emailError: string = "";
            let subjectError: string = "";
            let messageError: string = "";

            if(!this.state.name) {
                nameError = "Bitte geben Sie Ihren Namen an";
            } else if(this.state.name.length < 4) {
                nameError = "Bitte geben Sie einen längeren Namen an";
            } else if(this.state.name.length > 200) {
                nameError = "Bitte geben Sie einen kürzeren Namen an";
            }

            if(!this.state.email || !this.state.email.toLowerCase().match(emailValidator)) {
                emailError = "Bitte giben Sie eine gültige E-Mail-Adresse an";
            }

            if(!this.state.subject) {
                subjectError = "Bitte geben Sie Ihren Betreff an";
            } else if(this.state.subject.length < 4) {
                subjectError = "Bitte geben Sie einen längeren Betreff an";
            } else if(this.state.subject.length > 200) {
                subjectError = "Bitte geben Sie einen kürzeren Betreff an";
            }

            if(!this.state.message) {
                messageError = "Bitte geben Sie Ihren Nachricht an";
            } else if(this.state.message.length < 10) {
                messageError = "Bitte fassen Sie sich etwas ausführlicher";
            }

            this.setState({
                nameError: nameError,
                emailError: emailError,
                subjectError: subjectError,
                messageError: messageError,
            }, () => {
                resolve((nameError || emailError || subjectError || messageError) ? false : true);
                return;
            });
        });
    }

    private async submit() {
        this.setState({
            showErrors: true,
        }, async () => {
            if(await this.checkInputs()) {
                this.setState({
                    sending: true,
                }, async () => {
                    this.messages.current?.clear();
                    await this.props.dataService.contact(
                        this.state.name,
                        this.state.email,
                        this.state.subject,
                        this.state.message
                    )
                    .then(() => {
                        this.setState({
                            sending: false,
                            name: "",
                            email: "",
                            subject: "",
                            message: "",
                            showErrors: false,
                        }, () => this.messages.current?.show([{ severity: "success", summary: "Deine Kontaktaufnahme wurde verschickt.", sticky: true }]));
                    })
                    .catch(() => {
                        this.setState({
                            sending: false
                        }, () => this.messages.current?.show([{ severity: "error", summary: "Es ist ein Fehler aufgetreten.", sticky: true }]));
                    });
                });
            }
        });
    }

}