import React from "react";
import { BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";

export default class NotFound extends React.Component<{}, {}> {
    public render() {
        return(
            <div className="fullwidth full-centered">
                <span className="font-xxl primary">
                    <BsSearch/>
                </span>
                <br/>
                Wir konnten diese Seite leider nicht finden...
                <br/>
                <Link to={"/"}>Zurück zur Startseite</Link>
            </div>
        );
    }
}