import React from "react"
import DataService from "../../services/DataService";
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { emailValidator } from "../../models/Validators";
import { Messages } from 'primereact/messages';
import { BsCheckCircle } from "react-icons/bs";
import { Divider } from 'primereact/divider';

export interface IRegisterFormProps {
    dataService: DataService;
}

interface IRegisterFormState {
    showErrors: boolean;
    sending: boolean;
    registerSuccess: boolean;

    email: string;
    name: string;
    password: string;
    passwordConfirm: string;
    consent: boolean;

    emailError: string;
    nameError: string;
    passwordError: string;
    passwordConfirmError: string;
    consentError: string;
}

const pwPattern: RegExp = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,200}$");

export default class RegisterForm extends React.Component<IRegisterFormProps, IRegisterFormState> {

    private messages: React.RefObject<Messages> = React.createRef<Messages>();

    constructor(props: IRegisterFormProps) {
        super(props);

        this.state = {
            showErrors: false,
            sending: false,
            email: "",
            name: "",
            password: "",
            passwordConfirm: "",
            consent: false,
            emailError: "",
            nameError: "",
            passwordError: "",
            passwordConfirmError: "",
            consentError: "",
            registerSuccess: false,
        }
    }

    public render() {
        return(
            <div className="form">

                <form onSubmit={(ev) => {ev.preventDefault();}}>

                    {!this.state.registerSuccess && <>

                        <Messages className="field" ref={this.messages} />

                        <div className="field">
                            <div className="p-float-label field-input">
                                <InputText id="email" className={"block" + ((this.state.showErrors && this.state.emailError) ? " p-invalid" : "")} value={this.state.email} onChange={(e) => this.onChangeEmail(e.target.value)} aria-describedby="email-error" />
                                <label htmlFor="email" className="block">E-Mail</label>
                            </div>
                            {this.state.showErrors && this.state.emailError &&
                                <small id="email-error" className="p-error block">{this.state.emailError}</small>
                            }
                        </div>

                        <div className="field">
                            <div className="p-float-label field-input">
                                <InputText id="name" className={"block" + ((this.state.showErrors && this.state.nameError) ? " p-invalid" : "")} value={this.state.name} onChange={(e) => this.onChangeName(e.target.value)} aria-describedby="name-error" />
                                <label htmlFor="name" className="block">Name</label>
                            </div>
                            {this.state.showErrors && this.state.nameError &&
                                <small id="name-error" className="p-error block">{this.state.nameError}</small>
                            }
                        </div>

                        <div className="field">
                            <div className="p-float-label field-input">
                                <Password
                                    id="password"
                                    className={"block" + ((this.state.showErrors && this.state.passwordError) ? " p-invalid" : "")}
                                    value={this.state.password}
                                    onChange={(e) => this.onChangePassword(e.target.value)}
                                    aria-describedby="password-error"
                                    toggleMask
                                    promptLabel="Bitte geben Sie ein Passwort ein"
                                    weakLabel="Schwach"
                                    mediumLabel="Mittel"
                                    strongLabel="Stark"
                                    strongRegex={pwPattern.source}
                                    footer={<>
                                        <Divider/>
                                        <p>Anforderungen:</p>
                                        <ul style={{lineHeight: '1.5'}}>
                                            <li>Kleinbuchstabe</li>
                                            <li>Großbuchstabe</li>
                                            <li>Zahl</li>
                                            <li>Sonderzeichen</li>
                                            <li>Mindestens 8 Stellen</li>
                                        </ul>
                                    </>}
                                    />
                                <label htmlFor="password" className="block">Passwort</label>
                            </div>
                            {this.state.showErrors && this.state.passwordError &&
                                <small id="password-error" className="p-error block">{this.state.passwordError}</small>
                            }
                        </div>

                        <div className="field">
                            <div className="p-float-label field-input">
                                <Password id="passwordConfirm" className={"block" + ((this.state.showErrors && this.state.passwordConfirmError) ? " p-invalid" : "")} value={this.state.passwordConfirm} onChange={(e) => this.onChangePasswordConfirm(e.target.value)} aria-describedby="passwordConfirm-error" feedback={false} toggleMask />
                                <label htmlFor="passwordConfirm" className="block">Passwort wiederholen</label>
                            </div>
                            {this.state.showErrors && this.state.passwordConfirmError &&
                                <small id="passwordConfirm-error" className="p-error block">{this.state.passwordConfirmError}</small>
                            }
                        </div>

                        <div className="field">
                            <div className="p-float-label field-input">
                                <Checkbox id="consent" className={"block" + ((this.state.showErrors && this.state.consentError) ? " p-invalid" : "")} checked={this.state.consent} onChange={(e) => this.onChangeConsent(e.checked)} aria-describedby="consent-error" />
                                <label htmlFor="consent" className="block checkbox-label">Ich stimme den Datenschutzbestimmungen zu</label>
                            </div>
                            {this.state.showErrors && this.state.consentError &&
                                <small id="consent-error" className="p-error block">{this.state.consentError}</small>
                            }
                        </div>

                        <Button type="submit" style={{marginTop: "25px"}} label="Registrieren" icon="pi pi-send" loading={this.state.sending} onClick={() => this.submit()} />

                    </>}

                    {this.state.registerSuccess && <>
                    
                        <div className="success-message">
                            <BsCheckCircle className="success-message-icon"/>
                            <br/>
                            Wir haben Ihnen eine Nachricht zur Bestätigung Ihrer E-Mail-Adresse geschickt.
                            <br/>
                            Falls Sie die Nachricht nicht finden, schauen Sie bitte auch in Ihren Spam-Ordner.
                            <br/>
                            Sobald Sie die Bestätigung durchgeführt haben können Sie Ihr Konto nutzen.
                        </div>

                    </>}

                </form>

            </div>
        );
    }

    private onChangeEmail(value: string) {
        this.setState({
            email: value,
        }, () => {
            this.checkInputs();
        });
    }

    private onChangeName(value: string) {
        this.setState({
            name: value,
        }, () => {
            this.checkInputs();
        });
    }

    private onChangePassword(value: string) {
        this.setState({
            password: value,
        }, () => {
            this.checkInputs();
        });
    }

    private onChangePasswordConfirm(value: string) {
        this.setState({
            passwordConfirm: value,
        }, () => {
            this.checkInputs();
        });
    }

    private onChangeConsent(value: boolean) {
        this.setState({
            consent: value,
        }, () => {
            this.checkInputs();
        });
    }

    private async checkInputs(): Promise<boolean> {
        if(!this.state.showErrors)
            return true;
        
        return new Promise<boolean>(resolve => {
            let emailError: string = "";
            let nameError: string = "";
            let passwordError: string = "";
            let passwordConfirmError: string = "";
            let consentError: string = "";

            if(!this.state.email || !this.state.email.toLowerCase().match(emailValidator)) {
                emailError = "Bitte giben Sie eine gültige E-Mail-Adresse an";
            }

            if(!this.state.name) {
                nameError = "Bitte geben Sie Ihren Namen an";
            } else if(this.state.name.length < 5) {
                nameError = "Bitte geben Sie einen längeren Namen an";
            } else if(this.state.name.length > 50) {
                nameError = "Bitte geben Sie einen kürzeren Namen an";
            }

            if(!this.state.password || this.state.password.length < 8 || this.state.password.length > 200 || !pwPattern.test(this.state.password)) {
                passwordError = "Bitte geben Sie ein gültiges Passwort an";
            }

            if(this.state.password !== this.state.passwordConfirm) {
                passwordConfirmError = "Ihre Passworter stimmen nicht überein";
            }

            if(!this.state.consent) {
                consentError = "Sie müssen den Bedingungen zustimmen";
            }

            this.setState({
                emailError: emailError,
                nameError: nameError,
                passwordError: passwordError,
                passwordConfirmError: passwordConfirmError,
                consentError: consentError
            }, () => {
                resolve((emailError || nameError || passwordError || passwordConfirmError || consentError) ? false : true);
                return;
            });
        });
    }

    private async submit() {
        this.setState({
            showErrors: true,
        }, async () => {
            if(await this.checkInputs()) {
                this.setState({
                    sending: true,
                }, async () => {
                    this.messages.current?.clear();
                    await this.props.dataService.register(
                        this.state.email,
                        this.state.name,
                        this.state.password,
                        this.state.passwordConfirm,
                        this.state.consent
                    )
                    .then(() => {
                        this.setState({
                            sending: false,
                            email: "",
                            name: "",
                            password: "",
                            passwordConfirm: "",
                            consent: false,
                            showErrors: false,
                            registerSuccess: true,
                        });
                    })
                    .catch((e) => {
                        let errorCode: number = 0;
                        if(isNaN(+e)) {
                            errorCode = e.response.status;
                        } else {
                            errorCode = e;
                        }

                        let message: string;
                        switch(errorCode) {
                            default:
                                message = "Es ist ein unbekannter Fehler aufgetreten";
                                console.error(e);
                        }

                        this.setState({
                            sending: false
                        }, () => this.messages.current?.show([{ severity: "error", summary: message, sticky: true }]));
                    });
                });
            }
        });
    }

}