import React from 'react';
import './App.scss';
import "./assets/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import PrimeReact from 'primereact/api';
import Home from './components/pages/Home';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Datenschutz from './components/pages/Datenschutz';
import Impressum from './components/pages/Impressum';
import Footer from './components/shared/Footer';
import NavBar from './components/shared/NavBar';
import DataService from './services/DataService';
import NotFound from './components/pages/NotFound';
import Forum from './components/pages/Forum';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';

PrimeReact.inputStyle = 'filled';

interface IAppState {
	loaded: boolean;
	sidebar: boolean;
}

export default class App extends React.Component<{}, IAppState> {

	private dataService: DataService = new DataService();

	constructor(props: {}) {
		super(props);

		this.state = {
			loaded: false,
			sidebar: true
		}
	}

	componentDidMount() {
		this.loadUser();
	}

	private async loadUser() {
		this.dataService.me().then(() => this.setState({loaded: true})).catch(() => {console.log("error loading user")});
	}

	public render(): JSX.Element {
		return(
			<>
				<Navigation changeSidebar={(active?: boolean) => this.changeSidebar(active)}/>
				<div id='fullpage'>
					<div id='fullpage-content'>
						<Routes>
							{this.state.loaded && this.dataService.currentUser &&
								<Route path="/forum/*" element={<Forum sidebar={this.state.sidebar} dataService={this.dataService} />} />
							}
							{(!this.state.loaded || !this.dataService.currentUser) &&
								<Route path="/forum/*" element={<Login dataService={this.dataService} onLogin={() => this.setState({loaded: true})} />} />
							}
							<Route path='/abmelden' element={<LogoutWrapper dataService={this.dataService} onLogout={() => new Promise<void>(resolve => this.setState({loaded: true}, () => resolve()))} />} />
							<Route path="/impressum" element={<Impressum/>} />
							<Route path="/datenschutz" element={<Datenschutz/>} />
							<Route path="/" element={<Home dataService={this.dataService} />} />
							<Route path="*" element={<NotFound/>} />
						</Routes>
					</div>
					<Footer/>
				</div>
			</>
		);
	}

	private changeSidebar(active: boolean = !this.state.sidebar) {
		this.setState({
			sidebar: active
		});
	}

}

const Navigation = (props: {changeSidebar: (active?: boolean) => void}) => {
	const location = useLocation()
  
	return <NavBar location={location} changeSidebar={props.changeSidebar} />
}

const LogoutWrapper = (props: {dataService: DataService, onLogout: () => Promise<void>}) => {
	const navigation = useNavigate()
  
	return <Logout navigation={navigation} {...props} />
}