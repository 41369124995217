import React from "react";
import { Link } from "react-router-dom";
import { ISidebarItem } from "../../models/SidebarItem";

export interface ISidebarProps {
    model: ISidebarItem[];
}

export default class Sidebar extends React.Component<ISidebarProps, {}> {

    render() {
        return(
            <ul className="sidebar">
                {this.props.model.map(cat => {
                    return(
                        <li className="sidebar-category" role="none">
                            <div className="sidebar-category-label">{cat.label}</div>
                            <ul role="menu">
                                {cat.items.map(item => {
                                    return(
                                        <li className="sidebar-item" role="none">
                                            <Link to={item.url}>{item.icon && item.icon} {item.label}</Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        );
    }

}