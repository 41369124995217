import axios from "axios";
import moment from "moment";
import { IAuth } from "../models/Auth";
import { IUser } from "../models/User";

const loginCookie: string = "db.auth";
const isoDateFormat: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/; // eslint-disable-line

export default class DataService {

    private token: IAuth | null;
    public currentUser: IUser | null = null;

    constructor() {
        let cookieToken: string | null = window.localStorage.getItem(loginCookie);
        this.token = cookieToken ? JSON.parse(cookieToken) : null;
        this.handleDates(this.token);
    }

    public async login(email: string, password: string): Promise<IUser> {
        await this.logout();
        let auth: IAuth = await this.request<IAuth>("auth/authorize", "post", {
            email: email,
            password: password
        }, false);
        this.setToken(auth);
        return this.me(); 
    }

    public async logout() {
        await this.request("auth/revoke", "post").catch(() => {});
        this.setToken(null);
        this.currentUser = null;
    }
    
    public async me(): Promise<IUser> {
        if(!this.currentUser)
            this.currentUser = await this.request<IUser>("users/me");

        return this.currentUser;
    }

    public async register(email: string, name: string, password: string, passwordConfirm: string, consent: boolean): Promise<IUser> {
        return this.request("users", "post", {
            "name": name,
            "email": email,
            "password": password,
            "password_confirmation": passwordConfirm,
            "consent": consent
        }, false);
    }

    public async contact(name: string, email: string, subject: string, message: string) {
        return this.request("contact", "POST", {
            "name": name,
            "email": email,
            "subject": subject,
            "message": message
        }, false);
    }

    private async request<T>(endpoint: string, method: string = "get", data: any = null, auth: boolean = true): Promise<T> {
        return new Promise<T>(async (resolve, reject) => {

            let token: string = "";
            if(auth) {
                let now: Date = new Date();
                if(!this.token || this.token.refresh_exp < now) {
                    reject(401);
                    return;
                } else if(this.token.token_exp < now && !endpoint.startsWith("auth")) {
                    this.setToken(await this.request<IAuth>("auth/token", "post"));
                    token = this.token.access_token;
                } else {
                    token = this.token.access_token;
                }
            }

            const client = axios.create({baseURL: process.env.REACT_APP_API_URL});
            client.interceptors.response.use(resp => {
                this.handleDates(resp.data);
                return resp;
            });

            client.request({
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                method: method,
                url: process.env.REACT_APP_API_URL + endpoint,
                data: JSON.stringify(data)
            })
            .then((response) => {
                if(response.status >= 300) {
                    reject(response.status);
                    return;
                }
                
                resolve(response.data);
                return;
            })
            .catch((error) => {
                reject(error);
                return;
            });
        });
    }

    private isIsoDateString(value: any): boolean {
        return value && typeof value === "string" && isoDateFormat.test(value);
    }
        
    private handleDates(body: any) {
        if (body === null || body === undefined || typeof body !== "object")
            return body;
        
        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isIsoDateString(value)) body[key] = moment(value).toDate();
            else if (typeof value === "object") this.handleDates(value);
        }
    }

    private setToken(auth: IAuth | null) {
        this.token = auth;
        if(auth == null) {
            window.localStorage.removeItem(loginCookie);
        } else {
            window.localStorage.setItem(loginCookie, JSON.stringify(auth));
        }
    }

}