import React from "react";
import { MdForum } from "react-icons/md";

export default class Dashboard extends React.Component<{}, {}> {
    render() {
        return(
            <div className="full-centered">
                    <span className="font-xxl primary">
                        <MdForum/>
                    </span>
                    <br/>
                    Willkommen im Forum
            </div>
        );
    }
}