import React from "react";
import { NavigateFunction } from "react-router-dom";
import DataService from "../../services/DataService";

export class ILogoutProps {
    dataService: DataService;
    navigation: NavigateFunction;
    onLogout: () => Promise<void>;
}

export default class Logout extends React.Component<ILogoutProps, {}> {
    constructor(props: ILogoutProps) {
        super(props);

        props.dataService.logout().then(() => this.props.onLogout().then(() => this.props.navigation("/forum")));
    }
    
    render() {
        return(<></>);
    }
}