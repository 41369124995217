import React from "react";
import { BiLogOut } from "react-icons/bi";
import { BsHouseFill } from "react-icons/bs";
import { Route, Routes } from "react-router-dom";
import { ISidebarItem } from "../../models/SidebarItem";
import DataService from "../../services/DataService";
import Sidebar from "../shared/Sidebar";
import Dashboard from "./Forum/Dashboard";

export interface IForumProps {
    dataService: DataService;
    sidebar: boolean;
}

export default class Forum extends React.Component<IForumProps, {}> {

    public render() {
        return(
            <>
                <div id="sidebar" className={this.props.sidebar ? "" : "sidebar-hidden"}>
                    <Sidebar model={SidebarMenu} />
                </div>
                <div id="forum-layout" className={this.props.sidebar ? "" : "forum-layout-full"}>
                    <Routes>
                        <Route path="*" element={<Dashboard/>} />
                    </Routes>
                </div>
            </>
        );
    }

}

const SidebarMenu: ISidebarItem[] = [
    {
        label: 'Home',
        items: [
            {
                label: 'Dashboard',
                url: '/forum',
                icon: <BsHouseFill/>
            },
            {
                label: 'Abmelden',
                url: '/abmelden',
                icon: <BiLogOut style={{transform: "scaleX(-1)"}}/>
            }
        ]
    },
    {
        label: 'Chaträume',
        items: [
            { label: 'Form', url: '/forum' },
            { label: 'Input', url: '/forum' },
            { label: "Float",url: "/forum" },
            { label: "Invalid", url: "/forum" },
            { label: 'Buturln', url: '/forum' },
            { label: 'Table', url: '/forum' },
            { label: 'List', url: '/forum' },
            { label: 'Tree', url: '/forum' },
            { label: 'Panel', url: '/forum' },
            { label: 'Overlay', url: '/forum' },
            { label: "Media", url: "/forum" },
            { label: 'Menu', url: '/forum' },
            { label: 'Message', url: '/forum' },
            { label: 'File', url: '/forum' },
            { label: 'Chart', url: '/forum' },
            { label: 'Misc', url: '/forum' },
        ]
    },
];