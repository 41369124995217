import React from "react";
import { CgMenu } from "react-icons/cg";
import { BsFillChatFill, BsFillFileTextFill, BsHouseFill } from "react-icons/bs";
import { Link, Location } from "react-router-dom";

export interface INavBarProps {
    location: Location;
    changeSidebar: (active?: boolean) => void;
}

export default class NavBar extends React.Component<INavBarProps, {}> {

    componentDidMount() {
        document.addEventListener('scroll', () => {
			if(window.scrollY > 150) {
				document.documentElement.dataset.scroll = "1";
			} else {
				document.documentElement.dataset.scroll = "0";
			}
		});
    }

    render() {
        return(
            <nav className="navbar">
                <div>
                    {this.props.location.pathname.toLowerCase().startsWith("/forum") && 
                        <div onClick={() => this.props.changeSidebar()} className="navbar-burger"><CgMenu/></div>
                    }
                </div>
                <div>
                    <div><Link to="/"><BsHouseFill/> Start</Link></div>
                    <div><Link to="/"><BsFillFileTextFill/> Beiträge</Link></div>
                    <div><Link to="/forum"><BsFillChatFill/> Forum</Link></div>
                </div>
            </nav>
        );
    }

}