import React from "react";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

export default class Footer extends React.Component<{}, {}> {

    public render() {
        return(
            <div id="footer" className="content">
                <div>
                    <span className="footer-item"><Link to="/">&copy; J&uuml;rgen Effenberger 2022</Link></span>
                    <span className="footer-item"><Link to="/impressum">Impressum</Link></span>
                    <span className="footer-item"><Link to="/datenschutz">Datenschutz</Link></span>
                </div>
                <div>
                    <span className="footer-item">
                        <HashLink to="/#contact">
                            <span className="icon-text"><MdEmail/>&ensp;<span>Kontakt</span></span>
                        </HashLink>
                    </span>
                </div>
            </div>
        );
    }
}