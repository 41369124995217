import React from "react";
import DataService from "../../services/DataService";
import { TabView, TabPanel } from 'primereact/tabview';
import LoginForm from "../forms/LoginForm";
import RegisterForm from "../forms/RegisterForm";

export interface ILoginProps {
    dataService: DataService;
    onLogin: () => void;
}

interface ILoginState {

}

export default class Login extends React.Component<ILoginProps, ILoginState> {
    constructor(props: ILoginProps) {
        super(props);

        this.state = {

        }
    }

    public render() {
        return(
            <div className="content">
                <h1 className="font-xl headline">Forum</h1>

                <div className="box centered login">

                    <TabView>

                        <TabPanel header="Anmelden">
                            <LoginForm dataService={this.props.dataService} onLogin={this.props.onLogin} />
                        </TabPanel>

                        <TabPanel header="Registrieren">
                            <RegisterForm dataService={this.props.dataService} />
                        </TabPanel>

                    </TabView>
                
                </div>
            </div>
        );
    }
}